@import '@we-make-websites/styles-lib/src/config/configuration';
.collectionIntroCard {
  background-color: var(--color-background-grey);
  padding: var(--spacing-xl);

  &__title {
    margin-block-end: var(--spacing-4xs);
  }

  &__footer {
    margin-block-start: var(--spacing-2xl);
  }

  &__featuresTitle {
    margin-block-end: var(--spacing-s);
  }

  &__features {
    display: flex;
    gap: var(--spacing-l);
  }

  &__icon {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    justify-content: flex-start;
    text-align: center;
  }

  &#{&}--dark {
    background-color: var(--color-background-dark);
    color: var(--color-text-light);
  }

  @include mq($from: l) {
    display: flex;
    flex-direction: column;
    // Applies visual alignment with the product cards
    height: 100%;

    &__description {
      margin-block-end: var(--spacing-xl);
    }

    &__footer {
      margin-block-start: auto;
    }

    &__featuresTitle {
      margin-block-end: var(--spacing-m);
    }

    &__features {
      flex-direction: column;
      gap: calc(var(--spacing-m) + var(--spacing-2xs));
    }

    &__icon {
      text-align: start;
      flex-direction: row;
      gap: var(--spacing-m);
    }
  }
}
